import { parsePhoneNumber } from 'react-phone-number-input';
import { getCurrency, getDocumentType, getIsoCode } from '../../../logic/rebill';
import { generateURL } from '../Helpers/generateURL';
import { translateDocument } from '../../../utils/translateDocument';

const {
  NODE_ENV,
  REACT_APP_OCEANO_SETCONTRACTSTATUS,
  REACT_APP_OCEANO_STRIPESUBSCRIPTION,
  REACT_APP_OCEANO_UPDATECONTRACT_MP,
  REACT_APP_OCEANO_UPDATECONTRACT_ST,
  REACT_APP_OCEANO_UPDATECONTRACT_REBILL,
  REACT_APP_OCEANO_GENERATELINK,
  REACT_APP_OCEANO_GETPAYMENTLINK,
  REACT_APP_OCEANO_PAYMENT_PENDING,
} = process.env;

const {
  REACT_APP_REBILL_TEST_ORG_ID,
  REACT_APP_REBILL_TEST_API_KEY,
  REACT_APP_REBILL_TEST_API_URL,
  REACT_APP_REBILL_TEST_TOKEN_OLD,
  REACT_APP_REBILL_TEST_ORG_ID_OLD,
  REACT_APP_REBILL_TEST_API_KEY_OLD,
  REACT_APP_REBILL_TEST_API_URL_OLD,
  REACT_APP_REBILL_PRD_ORG_ID,
  REACT_APP_REBILL_PRD_API_KEY,
  REACT_APP_REBILL_PRD_API_URL,
  REACT_APP_REBILL_PRD_TOKEN,
  REACT_APP_REBILL_CL_PRD_API_KEY,
  REACT_APP_REBILL_CL_PRD_ORG_ID,
  REACT_APP_REBILL_MX_PRD_API_KEY,
  REACT_APP_REBILL_MX_PRD_ORG_ID,
  REACT_APP_REBILL_UY_PRD_API_KEY,
  REACT_APP_REBILL_UY_PRD_ORG_ID,
  REACT_APP_REBILL_COP_PRD_API_KEY,
  REACT_APP_REBILL_COP_PRD_ORG_ID,
} = process.env;

const itsProduction = NODE_ENV === 'production';

export const URLS = {
  MP: generateURL(REACT_APP_OCEANO_UPDATECONTRACT_MP),
  STRIPE: generateURL(REACT_APP_OCEANO_STRIPESUBSCRIPTION),
  REBILL: generateURL(REACT_APP_OCEANO_UPDATECONTRACT_REBILL),
  UPDATE_CONTRACT: generateURL(REACT_APP_OCEANO_UPDATECONTRACT_ST),
  SET_CONTRACT_STATUS: generateURL(REACT_APP_OCEANO_SETCONTRACTSTATUS),
  GENERATE_LINK: generateURL(REACT_APP_OCEANO_GENERATELINK),
  GET_PAYMENT_LINK: generateURL(REACT_APP_OCEANO_GETPAYMENTLINK),
  PENDING_PAYMENT: generateURL(REACT_APP_OCEANO_PAYMENT_PENDING),
};

export const REBILL_CL_CONF = {
  ORG_ID: itsProduction ? REACT_APP_REBILL_CL_PRD_ORG_ID : REACT_APP_REBILL_TEST_ORG_ID,
  API_KEY: itsProduction ? REACT_APP_REBILL_CL_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
  URL: itsProduction ? REACT_APP_REBILL_PRD_API_URL : REACT_APP_REBILL_TEST_API_URL,
  TOKEN: itsProduction ? REACT_APP_REBILL_CL_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
};

export const REBILL_MX_CONF = {
  ORG_ID: itsProduction ? REACT_APP_REBILL_MX_PRD_ORG_ID : REACT_APP_REBILL_TEST_ORG_ID,
  API_KEY: itsProduction ? REACT_APP_REBILL_MX_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
  URL: itsProduction ? REACT_APP_REBILL_PRD_API_URL : REACT_APP_REBILL_TEST_API_URL,
  TOKEN: itsProduction ? REACT_APP_REBILL_MX_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
};

export const REBILL_UY_CONF = {
  ORG_ID: itsProduction ? REACT_APP_REBILL_UY_PRD_ORG_ID : REACT_APP_REBILL_TEST_ORG_ID,
  API_KEY: itsProduction ? REACT_APP_REBILL_UY_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
  URL: itsProduction ? REACT_APP_REBILL_PRD_API_URL : REACT_APP_REBILL_TEST_API_URL,
  TOKEN: itsProduction ? REACT_APP_REBILL_UY_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
};

export const REBILL_COP_CONF = {
  ORG_ID: itsProduction ? REACT_APP_REBILL_COP_PRD_ORG_ID : REACT_APP_REBILL_TEST_ORG_ID,
  API_KEY: itsProduction ? REACT_APP_REBILL_COP_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
  URL: itsProduction ? REACT_APP_REBILL_PRD_API_URL : REACT_APP_REBILL_TEST_API_URL,
  TOKEN: itsProduction ? REACT_APP_REBILL_COP_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY,
};

export const REBILL_CONF = {
  ORG_ID: itsProduction ? REACT_APP_REBILL_PRD_ORG_ID : REACT_APP_REBILL_TEST_ORG_ID_OLD,
  API_KEY: itsProduction ? REACT_APP_REBILL_PRD_API_KEY : REACT_APP_REBILL_TEST_API_KEY_OLD,
  URL: itsProduction ? REACT_APP_REBILL_PRD_API_URL : REACT_APP_REBILL_TEST_API_URL_OLD,
  TOKEN: itsProduction ? REACT_APP_REBILL_PRD_TOKEN : REACT_APP_REBILL_TEST_TOKEN_OLD,
  PRICES: {
    STRIPE: {
      MX: [1, 3, 6, 9, 12, 'FREEMIUM'],
      CL: [1, 3, 6, 8, 'FREEMIUM'],
      UY: [1, 3, 6, 9, 12, 'FREEMIUM'],
      PYG: [1, 3, 6, 9, 12, 'FREEMIUM'],
      PEN: [1, 3, 6, 9, 12, 'FREEMIUM'],
      COP: [1, 3, 6, 9, 12, 'FREEMIUM'],
      USD: [1, 3, 6, 9, 12, 15, 'FREEMIUM'],
      HNL: [1, 3, 6, 9, 12, 'FREEMIUM'],
      CRC: [1, 3, 6, 9, 12, 'FREEMIUM'],
      BOB: [1, 3, 6, 9, 12, 'FREEMIUM'],
    },
    MP: {
      MX: [1, 3, 6, 9, 12, 'FREEMIUM'],
      CL: [1, 3, 6, 8, 'FREEMIUM'],
      AR: [1, 3, 6, 9, 12, 'FREEMIUM'],
    },
    REBILL: {
      MX: [1, 3, 6, 9, 12, 'FREEMIUM'],
      UY: [1, 3, 6, 9, 12, 'FREEMIUM'],
      COP: [1, 3, 6, 9, 12, 'FREEMIUM'],
      CL: [1, 3, 6, 8, 'FREEMIUM'],
    },
  },
};

const getPaymentKey = (provider, country, duration) => {
  switch (provider) {
    case 'STRIPE':
      return getStripeKey(country, duration);
    case 'MP':
      return getMPKey(country, duration);
    case 'REBILL':
      return getRebillKey(country, duration);
    default:
      return null; // Handle unknown provider
  }
};

const getStripeKey = (country, duration) => {
  const keyPrefix = itsProduction
    ? process.env[`REACT_APP_REBILL_STRIPE_${country}_PRD_${duration}`]
    : process.env[`REACT_APP_REBILL_STRIPE_${country}_TEST_${duration}`];
  return keyPrefix;
};

const getMPKey = (country, duration) => {
  const keyPrefix = itsProduction
    ? process.env[`REACT_APP_REBILL_MP_${country}_PRD_${duration}`]
    : process.env[`REACT_APP_REBILL_MP_${country}_TEST_${duration}`];
  return `${keyPrefix}`;
};

const getRebillKey = (country, duration) => {
  const keyPrefix = itsProduction
    ? process.env[`REACT_APP_REBILL_${country}_PRD_${duration}`]
    : process.env[`REACT_APP_REBILL_${country}_TEST_${duration}`];
  /*
  *
  * console.log({country,itsProduction},
  {
    key: `REACT_APP_REBILL_${country}_TEST_${duration}`,
    keyPrefix
  });
  * */
  return `${keyPrefix}`;
};

const getEnvPricesFrom = (CONFIG) => {
  const PRICES = {};

  Object.keys(CONFIG).forEach((provider) => {
    PRICES[provider] = {};
    Object.keys(CONFIG[provider]).forEach((country) => {
      PRICES[provider][country] = {};
      CONFIG[provider][country].forEach((duration) => {
        PRICES[provider][country][duration] = getPaymentKey(provider, country, duration);
      });
    });
  });

  return PRICES;
};

const PRICES = getEnvPricesFrom(REBILL_CONF.PRICES);

/*if (itsProduction) {
  console.warn({ PRICES, env: process.env });
} else {
  console.log({ PRICES, env: process.env });
}*/

export const getPlanPrice = (formikValues, sale) => {
  const { payment_method, advanceSuscription } = formikValues;
  const countryPayment = getIsoCode(formikValues.country);
  const gateway = payment_method;
  const isStripe = gateway.includes('Stripe');
  const isMercadoPago = gateway.includes('Mercado Pago');
  const quotes = Number(formikValues.quotes);

  const priceQuantity = advanceSuscription.isAdvanceSuscription
    ? advanceSuscription.firstQuoteDiscount
    : Number(Math.floor(sale.Grand_Total / quotes));

  console.log('getPlanPrice', formikValues, { countryPayment, priceQuantity });

  return {
    id: isStripe
      ? PRICES.STRIPE[countryPayment][quotes]
      : isMercadoPago
      ? PRICES.MP[countryPayment][quotes]
      : PRICES.REBILL[countryPayment][quotes],
    quantity: priceQuantity,
  };
};

export const getPlanPriceCheckout = (formikValues, sale, isTrial = false) => {
  const { payment_method, advanceSuscription } = formikValues;

  const countryPayment = getIsoCode(formikValues.country);
  const currencyPayment = getCurrency(formikValues.country);

  const gateway = payment_method;
  const isStripe = gateway.includes('Stripe');
  const isMercadoPago = gateway.includes('Mercado Pago');

  const quotes = Number(formikValues.quotes);

  const priceQuantity = advanceSuscription.isAdvanceSuscription
    ? advanceSuscription.info.firstQuoteDiscount
    : Number(Math.floor(sale.Grand_Total / quotes));

  if (isTrial) {
    return {
      id: isStripe
        ? PRICES.STRIPE[countryPayment]['FREEMIUM']
        : isMercadoPago
        ? PRICES.MP[countryPayment]['FREEMIUM']
        : PRICES.REBILL[countryPayment]['FREEMIUM'],
      quantity: 1,
    };
  }

  console.group('getPlanPriceCheckout');
  console.log({
    isTrial,
    countryPayment,
    currencyPayment,
    quotes,
    isStripe,
    formikValues,
    priceQuantity,
  });
  //console.log(PRICES.STRIPE, PRICES.STRIPE[countryPayment])
  console.groupEnd();

  return {
    id: isStripe
      ? PRICES.STRIPE[countryPayment][quotes]
      : isMercadoPago
      ? PRICES.MP[countryPayment][quotes]
      : PRICES.REBILL[countryPayment][quotes],
    quantity: priceQuantity,
  };
};

export const mappingFields = ({ formAttributes, contact, formikValues }) => {
  console.log('mapping', { formAttributes, contact, formikValues });

  const [number] = formAttributes.address.split(' ').filter((s) => !isNaN(s) && s);
  const [...street] = formAttributes.address.split(' ').filter((s) => isNaN(s) && s);
  const { phoneNumber } = formAttributes;
  const { countryCallingCode, nationalNumber } = phoneNumber;
  const { type } = getDocumentType(formAttributes.country);

  return {
    firstName: contact.First_Name,
    lastName: contact.Last_Name,
    email: formAttributes.email,
    phone: {
      countryCode: countryCallingCode,
      areaCode: '11',
      phoneNumber: nationalNumber,
    },
    birthday: contact.Date_of_Birth,
    personalId: {
      type,
      value: `${formAttributes.dni.replace('-', '')}`,
    },
    address: {
      street: street.join(' '),
      number: number,
      floor: '0',
      apt: '0',
      city: formikValues.country,
      state: formikValues.country,
      zipCode: formAttributes.zip,
      country: formikValues.country,
      description: 'Pago en la plataforma de SPP MSK',
    },
  };
};

export const mappingCheckoutFields = ({ paymentLinkCustomer, contact, checkout }) => {
  const [number] = paymentLinkCustomer.address.split(' ').filter((s) => !isNaN(s) && s);
  const [...street] = paymentLinkCustomer.address.split(' ').filter((s) => isNaN(s) && s);
  const { countryCallingCode, nationalNumber } = parsePhoneNumber(paymentLinkCustomer.phone);
  const type = translateDocument(contact.Tipo_de_Documento);

  return {
    firstName: contact.First_Name,
    lastName: contact.Last_Name,
    email: paymentLinkCustomer.email,
    phone: {
      countryCode: countryCallingCode,
      areaCode: '11',
      phoneNumber: nationalNumber,
    },
    birthday: contact.Date_of_Birth,

    personalId: {
      type,
      value: paymentLinkCustomer.personalId,
    },
    address: {
      street: street.join(' '),
      number: number,
      floor: '0',
      apt: '0',
      city: checkout.country,
      state: checkout.country,
      zipCode: paymentLinkCustomer.zip,
      country: checkout.country,
      description: 'Pago en la plataforma de CHECKOUT LINK SPP MSK ',
    },
  };
};
