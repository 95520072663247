import React from 'react';
import { useParams } from 'react-router';
import MotionSpinner from '../Spinner/MotionSpinner';
import { Container, Navbar, Image, Card, Content } from 'react-bulma-components';
import IMAGES from '../../../img/pasarelaCobros/share';
import { usePaymentPTP } from '../Hooks/usePaymentPTP';
import dashedDivider from '../../../img/pasarelaCobros/dashed_divider.svg';
const { logo } = IMAGES;

const StatusPTP = () => {
  const { requestId } = useParams();
  const { loading, data } = usePaymentPTP(requestId);
  const ptpStatus = data?.ptp?.status;

  const getStatusText = (status) => {
    const statusFront = {
      PENDING: 'Pendiente',
      FAILED: 'Pago Fallido',
      REJECTED: 'Pago Rechazado',
      APPROVED: 'Pago Aprobado',
    };
    return statusFront[status] || '';
  };

  const getCardColor = (status) => {
    const colorMap = {
      PENDING: 'is-secondary',
      FAILED: 'is-danger',
      REJECTED: 'is-danger',
      APPROVED: 'is-success',
    };
    return colorMap[status] || '';
  };

  return (
    <>
      {loading ? (
        <MotionSpinner />
      ) : (
        <Container className='grid-status'>
          <section>
            <div className='columns'>
              <div className='column'>
                <div className='is-flex is-justify-content-center'>
                  <Image src={logo} alt='MSK Logo' size={128} />
                </div>
                <div className='statusPTP_box'>
                  <div className='group'>
                    <div className='overlap-group'>
                      <div className='div px-5 pt-5'>
                        <h1 className='text-wrapper-2'> {getStatusText(ptpStatus?.status)}</h1>

                        {ptpStatus?.status === 'PENDING' && (
                          <p className='description'>
                            La petición se encuentra pendiente. Se notificará mediante e-mail cuando
                            cambie de estado.
                          </p>
                        )}
                      </div>
                      <img className='vector ' alt='Vector' src={dashedDivider} />
                      {ptpStatus?.status !== 'FAILED' && (
                        <div className='overlap-2 px-5 pt-4 mb-5'>
                          <h3 className='text-wrapper-3 mb-2'>Datos de pago</h3>
                          <p className='referencia-XXX-tipo'>
                            Referencia: {data?.ptp?.request?.payment?.reference}
                            <br />
                            Tipo de documento: {data?.ptp?.request?.payer?.documentType}
                            <br />
                            Número de documento: {data?.ptp?.request?.payer?.document}
                            <br />
                            Monto del pago: {data?.ptp?.request?.payment?.amount?.total}{' '}
                            {data?.ptp?.request?.payment?.amount?.currency}
                            <br />
                            Nombre completo: {data?.ptp?.request?.payer?.name}{' '}
                            {data?.ptp?.request?.payer?.surname}
                          </p>
                        </div>
                      )}
                      {/*  <button type='button' className='button buttonMSK ml-5'>
                        Ir al inicio
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      )}
    </>
  );
};

export default StatusPTP;
