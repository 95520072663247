import React, { useState } from 'react';
import { generateURL } from '../Helpers/generateURL';
import { fireModalAlert, fireToast } from '../Hooks/useSwal';
import { useYupValidation } from '../Hooks/useYupValidation';
import { ErrorMessage, Field, Formik, Form } from 'formik';

const MercadoPagoCheckout = ({ invoice }) => {
  const { formValues } = JSON.parse(invoice.checkoutPayment.paymentData);
  const { quotes, email, dni } = formValues;
  const [onPaymentRequest, setOnPaymentRequest] = useState(false);
  const [sendPayment, setSendPayment] = useState(false);

  const { cardMpValidation } = useYupValidation();

  const initialValues = {
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
  };


  const handleSubmit = async ({ cardNumber, expirationMonth, expirationYear, securityCode }) => {
    setOnPaymentRequest(true);

    const customerCard = {
      cardNumber,
      expirationMonth,
      expirationYear,
      securityCode,
      identification: {
        type: 'DNI',
        number: dni,
      },
    };


    const paymentData = {
      transactionAmount: formValues.amount,
      installments: quotes,
      description: `Pago de contrato (SO: ${invoice.checkoutPayment.contract_so})`,
      payer: {
        email: email,
        identification: {
          type: 'DNI',
          number: dni,
        },
      },
      paymentDetails: invoice.checkoutPayment,
      paymentData: customerCard,
    };

    try {
      const response = await fetch(generateURL('/api/gateway2/api/mercadopago/arg/our_test'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer $2y$12$zg.e9Gk2MpnXHrZfdJcFOuFsCdBh/kzrb61aiLSbDRFBruRwCqkZ6`,
        },
        body: JSON.stringify(paymentData),
      });

      const data = await response.json();
      console.log(data);
      if (response.ok) {
        fireModalAlert('Pago Exitoso', 'Se realizo el pago correctamente', 'success');
       /*  setTimeout(() => {
          window.location.reload(true);
        }, 3000); */
      } else {
        fireToast('Error al realizar el pago', 'error', 5000);
        console.error('Error al realizar el pago:', data);
      }
    } catch (error) {
      console.error('Error:', { error });
      fireToast(error.message, 'error', 5000);
    } finally {
      setOnPaymentRequest(false);
    }
  };

  return (
    <div className={`mpc-box ${onPaymentRequest ? 'mpc-form-requesting' : null}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={cardMpValidation}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue, errors }) => (
          <>
            <Form className='mpc-grid'>
              <div id='mpc-card' className={`mpc-field ${errors?.cardNumber && 'mpc-field-error'}`}>
                <Field
                  type='text'
                  name='cardNumber'
                  placeholder='Número de la tarjeta'
                  className='mpc-input'
                  onChange={(e) => setFieldValue('cardNumber', e.target.value.trim())}
                  onBlur={(e) => {
                    setFieldValue('cardNumber', e.target.value.trim());
                  }}
                  disabled={onPaymentRequest}
                />
              </div>
              <div
                id='mpc-expiration-month'
                className={`mpc-field ${errors?.expirationMonth && 'mpc-field-error'}`}
              >
                <Field
                  type='text'
                  name='expirationMonth'
                  placeholder='Mes de expiración (MM)'
                  className='mpc-input'
                  disabled={onPaymentRequest}
                />
              </div>
              <div
                id='mpc-expiration-year'
                className={`mpc-field ${errors?.expirationYear && 'mpc-field-error'}`}
              >
                <Field
                  type='text'
                  name='expirationYear'
                  placeholder='Año de expiración (YYYY)'
                  className='mpc-input'
                  disabled={onPaymentRequest}
                />
              </div>
              <div
                id='mpc-cvv'
                className={`mpc-field ${errors?.securityCode && 'mpc-field-error'}`}
              >
                <Field
                  type='text'
                  name='securityCode'
                  placeholder='Código de seguridad (CVV)'
                  className='mpc-input'
                  disabled={onPaymentRequest}
                />
              </div>

              <button
                id='mpc-submit'
                className={`mpc-button ${onPaymentRequest && ''}`}
                type='submit'
                disabled={onPaymentRequest || !(isValid && dirty) || isSubmitting}
              >
                {onPaymentRequest ? 'Procesando ...' : 'Pagar'}
              </button>
            </Form>
            <div className='errors'>
              <ErrorMessage name='cardNumber' component='span' className='error' />
              <ErrorMessage name='expirationMonth' component='span' className='error' />
              <ErrorMessage name='expirationYear' component='span' className='error' />
              <ErrorMessage name='securityCode' component='span' className='error' />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default MercadoPagoCheckout;
