/* eslint-disable import/prefer-default-export */
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function fireToast(title, icon = 'error', timer = 3000) {
  const toast = withReactContent(Swal);

  toast.fire({
    title: <p>{title}</p>,
    toast: true,
    icon,
    position: 'bottom-end',
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
    didOpen: (alert) => {
      alert.addEventListener('mouseenter', Swal.stopTimer);
      alert.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
}

export function fireAlert(title, text = '', icon = 'error', timer = 3000) {
  const toastAlert = withReactContent(Swal);

  toastAlert.fire({
    title: <p>{title}</p>,
    toast: false,
    text,
    icon,
    position: 'center-center',
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
    didOpen: (alert) => {
      alert.addEventListener('mouseenter', Swal.stopTimer);
      alert.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
}

export function fireModalAlert(title, html = '', icon = 'error', state = null) {
  const toastAlert = withReactContent(Swal);

  const handleOutsideClick = () => {
    // Verificar si estamos en la ruta /checkout/ptp
    if (window.location.hash.includes('/checkout/ptp') && state === null) {
      // Redireccionar a la página de inicio
      window.location.reload(true);
    }

    if (state?.redirect) {
      if (state.redirectSuffix) {
        window.location.href = '/#/status/ptp/' + state.redirectSuffix;
      } else {
        window.location.reload(); // for mp payments
      }
    }

    if (title.includes('Sale not found')) {
      window.location.reload();
    }
  };

  toastAlert.fire({
    title: <p>{title}</p>,
    toast: false,
    html,
    icon,
    position: 'center-center',
    showConfirmButton: true,
    preConfirm: handleOutsideClick,
    allowOutsideClick: handleOutsideClick,
  });
}

export async function fireModalAlertRedirect(title, html = '', payment) {
  const toast = withReactContent(Swal);
  const result = await toast.fire({
    title: <p>{title}</p>,
    toast: false,
    html,
    icon: 'warning',
    position: 'center-center',
    showConfirmButton: true,
    confirmButtonText: 'Ver el estado del pago',
  });

  if (result.isConfirmed) {
    window.location.href = `#/status/${payment.id}`;
  } else {
    window.location.href = `#/status/${payment.id}`;
  }
}
