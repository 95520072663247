import { generateURL } from '../components/PasarelaCobros/Helpers/generateURL';

export const getEnvKey = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return isProduction
    ? process.env.REACT_APP_STRIPE_MSK_PK_PROD
    : process.env.REACT_APP_STRIPE_MSK_PK_TEST;
};

export const createStripePaymentToken = async ({
  payment,
  currency,
  setClientSecret,
  setError,
  setFound,
  setWaiting,
  type,
}) => {
  let stripeUrl = type.includes('Suscripción')
    ? generateURL('/api/gateway/api/stripe/payment/sub')
    : generateURL('/api/gateway/api/stripe/payment/intent');
  const response = await fetch(stripeUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer $2y$12$zg.e9Gk2MpnXHrZfdJcFOuFsCdBh/kzrb61aiLSbDRFBruRwCqkZ6`,
    },
    body: JSON.stringify({
      amount: payment.advancePayment.info.quoteForMonth * 100,
      currency,
      payment,
    }),
  });

  const paymentIntent = await response.json();

  if (paymentIntent.status === 302) {
    if (paymentIntent.status === 'active') {
    }
    setFound('El pago realizado ha sido aprobado correctamente');
    setWaiting(false);
    return;
  }

  if (paymentIntent.error) {
    setError(paymentIntent.error);
    setWaiting(false);
    return;
  }

  setClientSecret(paymentIntent.response.client_secret);
  setWaiting(false);
};
