import React, { useEffect, useState } from 'react';
import IMAGES from '../../../img/pasarelaCobros/share';
import { useLocation, useParams } from 'react-router';
import axios from 'axios';

import { URLS } from '../Hooks/useRebill';
import { useContractZoho } from '../Hooks/useContractZoho';
import MotionSpinner from '../Spinner/MotionSpinner';
import {
  getCurrency
} from '../../../logic/rebill';
import { formatPrice } from '../Helpers/handleCheckoutData';
import CheckoutCard from './CheckoutCard';
import CheckoutTrialCard from './CheckoutTrialCard';
import CheckoutInput from "./CheckoutInput";

const { logo } = IMAGES;

const Checkout = () => {
  const [checkoutPayment, setCheckoutPayment] = useState(null);
  const [products, setProducts] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [sale, setSale] = useState(null);
  const [contact, setContact] = useState(null);
  const [ticketData, setTicketData] = useState(false);
  const [advancePayment, setAdvancePayment] = useState({});
  const [paymentAction, setPaymentAction] = useState(false);
  const [fetchContent, setFetchContent] = useState(true);
  const [currencyOptions, setCurrencyOptions] = useState({
    style: 'currency',
    currency: 'MXN',
  });

  const { so } = useParams();
  const { pathname } = useLocation();

  const needRunEffect = !pathname.includes('vp');
  const { loading, data: contractData } = useContractZoho(so, needRunEffect);

  const valuesAdvanceSuscription = ({ total, checkoutPayment }) => {
    const { quotes, type } = checkoutPayment;

    const isAdvanceSuscription = type.includes('Suscripción con anticipo');
    const isSuscription = type.includes('Suscripción') && !type.includes('anticipo');
    const isTraditional = type.includes('Tradicional');

    const detailValues = {
      isAdvanceSuscription,
      isSuscription,
      isTraditional,
      info: {},
    };

    if (isAdvanceSuscription) {
      const quoteForMonth = Math.floor(total / quotes);
      const remainingQuotes = quotes === 1 ? 1 : quotes - 1;

      const firstQuoteDiscount = Math.floor(quoteForMonth / 2);
      const remainingAmountToPay = total - firstQuoteDiscount;
      const payPerMonthAdvance = Math.floor(remainingAmountToPay / remainingQuotes);
      const adjustmentPayment = parseFloat(
        (payPerMonthAdvance * remainingQuotes + firstQuoteDiscount - total).toFixed(2),
      );

      detailValues.info = {
        remainingQuotes,
        firstQuoteDiscount,
        remainingAmountToPay,
        payPerMonthAdvance,
        adjustmentPayment,
      };
    } else if (isSuscription) {
      const quoteForMonth = Math.floor(total / quotes);
      const remainingQuotes = quotes === 1 ? 1 : quotes - 1;
      const payPerMonthAdvance = quoteForMonth;
      const adjustmentPayment = parseFloat((payPerMonthAdvance * quotes - total).toFixed(2));

      detailValues.info = {
        quoteForMonth,
        remainingQuotes,
        payPerMonthAdvance,
        adjustmentPayment,
      };
    } else {
      const quoteForMonth = Math.floor(total / 1);

      const payPerMonthAdvance = quoteForMonth;
      const adjustmentPayment = parseFloat((payPerMonthAdvance - total).toFixed(2));

      detailValues.info = {
        quoteForMonth,
        payPerMonthAdvance,
        adjustmentPayment,
      };
    }

    return detailValues;
  };

  const handleCheckoutData = (checkoutPayment, advanceSuscription) => {
    const { isAdvanceSuscription, isSuscription, isTraditional, info } = advanceSuscription;
    const checkoutTrial = Boolean(checkoutPayment?.trial);
    const auxResume = {
      totalMonths: 0,
      firstPay: 0,
      payPerMonth: 0,
      formattedFirstPay: 0,
      formattedPayPerMonth: 0,
      isTraditional,
      isAdvanceSuscription,
      isSuscription,
    };

    if (auxResume.isAdvanceSuscription) {
      auxResume.totalMonths = Number(checkoutPayment?.quotes);
      auxResume.firstPay = checkoutTrial ? 0 : info.firstQuoteDiscount;
      auxResume.payPerMonth = info.payPerMonthAdvance;

      auxResume.formattedFirstPay = formatPrice('MX', currencyOptions, auxResume.firstPay);
      auxResume.formattedPayPerMonth = formatPrice('MX', currencyOptions, auxResume.payPerMonth);
    } else if (auxResume.isSuscription) {
      auxResume.totalMonths = Number(checkoutPayment?.quotes);
      auxResume.firstPay = Math.floor(sale?.Grand_Total / auxResume.totalMonths);

      auxResume.formattedAmount = formatPrice('MX', currencyOptions, auxResume.firstPay);
      auxResume.formattedFirstPay = formatPrice(
        'MX',
        currencyOptions,
        checkoutTrial ? 0 : auxResume.firstPay,
      );
      auxResume.formattedPayPerMonth = formatPrice('MX', currencyOptions, auxResume.firstPay);
    } else {
      auxResume.totalMonths = 1;
      auxResume.firstPay = sale?.Grand_Total;

      auxResume.formattedAmount = formatPrice('MX', currencyOptions, auxResume.firstPay);
      auxResume.formattedFirstPay = formatPrice(
        'MX',
        currencyOptions,
        checkoutTrial ? 0 : auxResume.firstPay,
      );
      auxResume.formattedPayPerMonth = formatPrice('MX', currencyOptions, auxResume.firstPay);
    }

    return auxResume;
  };

  const { totalMonths, formattedFirstPay, formattedPayPerMonth, formattedAmount } = handleCheckoutData(checkoutPayment, advancePayment);



  useEffect(() => {
    if (!loading && !paymentAction) {
      fetchPaymentLink();
    }

    async function fetchPaymentLink() {
      const { GET_PAYMENT_LINK } = URLS;
      const { data } = await axios.get(`${GET_PAYMENT_LINK}/${so}`);

      //console.log(contractData);

      setCheckoutPayment(data.checkout);
      setCustomer(data.customer);
      setSale(contractData.sale);
      setContact(contractData.contact);
      setProducts(contractData.products);

      const inscription = valuesAdvanceSuscription({
        total: contractData.sale?.Grand_Total,
        checkoutPayment: data.checkout,
      });

      setAdvancePayment(inscription);

      const mergedData = {
        paymentLinkData: { ...data },
        ZohoData: { ...contractData },
        advanceSuscription: inscription,
      };

      const { currency } = getCurrency(data.checkout.country);
      setCurrencyOptions((prevState) => ({ ...prevState, currency }));

      setTicketData(mergedData);
      setFetchContent(false);


    }
  }, [contractData, paymentAction]);



  const invoiceDetail = {
    advancePayment,
    formattedFirstPay,
    formattedPayPerMonth,
    checkoutPayment,
  };

  const totalPrice = products?.reduce((total, p) => total + Math.floor(p.price * p.quantity), 0);

  const parametrosURL = window.location.hash.includes('trial');
  const isTrial = Boolean(checkoutPayment?.trial) || parametrosURL

  return (
    <>
      {loading ? (
        <MotionSpinner text="Recuperando contrato" />
      ) : (
        <main className='grid-checkout container'>
          <header className={`is-max-widescreen py-5`}>
            <nav
              className='navbar is-justify-content-center'
              role='navigation'
              aria-label='main navigation'
            >
              <div className='navbar-brand msk-logo'>
                <img src={logo} alt='MSK Logo' width='130' height='130' />
              </div>
            </nav>
          </header>
          <section className='container'>
            <div className='columns'>
              <div className='column'>
                {isTrial ? (
                  <CheckoutTrialCard
                    checkoutPayment={checkoutPayment}
                    invoiceDetail={invoiceDetail}
                    totalMonths={totalMonths}
                    ticketData={ticketData}
                    formattedFirstPay={formattedFirstPay}
                    formattedAmount={formattedAmount}
                    products={products}
                    totalPrice={totalPrice}
                    contractData={contractData}
                  />
                ) : (
                  <CheckoutCard
                    checkoutPayment={checkoutPayment}
                    invoiceDetail={invoiceDetail}
                    totalMonths={totalMonths}
                    currencyOptions={currencyOptions}
                    formattedFirstPay={formattedFirstPay}
                    formattedAmount={formattedAmount}
                    products={products}
                    totalPrice={totalPrice}
                    contractData={contractData}
                  />
                )}
              </div>
              {ticketData && <CheckoutInput
                checkoutPayment={checkoutPayment}
                invoiceDetail={invoiceDetail}
                contractData={contractData}
                ticketData={ticketData}
                currencyOptions={currencyOptions}
                isTrial={isTrial}
              />}
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default Checkout;
