/* eslint-disable no-undef */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../Provider/StateProvider';
import { useFormikContext } from 'formik';
import InputField from '../InputField';
import { FormStep } from './MultiStep';
import { motion } from 'framer-motion';
import { URLS } from '../Hooks/useRebill';
import { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import { useParams } from 'react-router';
import { fireModalAlert } from '../Hooks/useSwal';
import TrialCheckbox from '../../TrialCheckbox';

const RebillCheckoutForm = () => {
  const { contractData, formikValues, userInfo, setRebillFetching, setOpenBlockLayer } = useContext(AppContext);
  const { sale } = contractData;

  const [selectedCountry, setSelectedCountry] = useState( 'MX');
  const [showRebill, setShowRebill] = useState(false);
  const { id } = useParams();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [generateLink, setGenerateLink] = useState(false);

  const { values, handleChange, handleBlur, setFieldValue, touched, errors } = useFormikContext();

  const handlePhoneInputChange = (value) => {
    setFieldValue('phone', value);
    const numberText = value ? value : '';
    const fullPhoneNumber = parsePhoneNumber(numberText);

    if (fullPhoneNumber) {
      const { country } = fullPhoneNumber;
      const findCountry = country;
      setPhoneNumber(fullPhoneNumber);
      if (findCountry) {
        setSelectedCountry(findCountry);
      }
    }
  };

  const handleTrialInputChange = (value) => {

    if (!values.trial) {
      setFieldValue('trial', true);
    } else {
      setFieldValue('trial', false);
    }
  };

  const hasErrorInputs = useMemo(
    () => Object.values(errors).every((v) => typeof v !== 'undefined' && v != null && !!!v),
    [errors],
  );
  const completedInputs = useMemo(
    () => Object.values(values).every((v) => typeof v !== 'undefined' && v != null && v !== ''),
    [values],
  );

  useEffect(() => {
    return () => {
      setShowRebill(false);
      setFieldValue('cardHolder', false);
    };
  }, [completedInputs]);

  const handleGenerateLink = async (event) => {
    setGenerateLink(true);
    const { GENERATE_LINK } = URLS;
    const formValues = { ...formikValues, ...values };
    const paymentData = {
      formValues,
      userInfo,
    };

    const requestData = {
      email: values.email,
      phone: values.phone,
      personalId: values.dni,
      address: values.address,
      zip: values.zip,
      fullName: values.fullName,
      gateway: userInfo.stepTwo.value,
      type: userInfo.stepThree.value,
      contract_entity_id: id,
      contract_so: sale.SO_Number,
      country: sale.Pais_de_facturaci_n,
      quotes: values.quotes !== undefined ? values.quotes : 1,
      trial: values.trial,
      status: 'pending',
      paymentData: JSON.stringify(paymentData),
    };

    try {
      const { data } = await axios.post(GENERATE_LINK, requestData);
      setOpenBlockLayer(true);
      setRebillFetching({ loading: false, ...data });
      console.log({ data });
      setGenerateLink(false);
    } catch (e) {
      fireModalAlert('Error al generar link', e);
      //console.log({ e });
    }
  };

  //console.log({values,sale,userInfo})

  const isMpOrStripe = (userInfo.stepTwo.value.includes('Mercado Pago') || userInfo.stepTwo.value.includes('Stripe'))

  return (
    <FormStep stepNumber={5} stepName='Finaliza la compra'>
      <div id='payment_rebill'>
        <InputField
          type='text'
          id='fullName'
          name='fullName'
          label='Nombre del titular'
          placeholder='Ingresar nombre del titular'
          value={values.fullName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.fullName && errors.fullName}
        />
        <InputField
          type='phone'
          id='phone'
          name='phone'
          label='Teléfono'
          placeholder='Ingresar número de teléfono'
          value={values.phone}
          onChange={handlePhoneInputChange}
          onBlur={handleBlur}
          error={touched.phone && errors.phone}
          country={selectedCountry}
        />
        <InputField
          type='address'
          id='address'
          name='address'
          label='Dirección de facturación'
          placeholder='Dirección completa'
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.address && errors.address}
        />
        <InputField
          type='text'
          id='zip'
          name='zip'
          label='Codigo postal'
          placeholder='Codigo postal'
          value={values.zip}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.zip && errors.zip}
        />
        <InputField
          type='text'
          id='dni'
          name='dni'
          label='Número de identificación'
          placeholder='Número de identificación'
          value={values.dni}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.dni && errors.dni}
        />
        <InputField
          type='email'
          id='email'
          name='email'
          label='E-mail'
          placeholder='Ingresar e-mail'
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && errors.email}
        />

        { !isMpOrStripe && (
            <TrialCheckbox
            checked={values.trial}
            error={touched.trial && errors.trial}
            onChange={(e) => handleTrialInputChange(e)}
            />
        )}

        {completedInputs && hasErrorInputs && (
          <motion.div className='field mt-2 is-flex is-flex-direction-row is-justify-content-center'>
            <button
              className={`button is-info ml-2 ${generateLink && 'is-loading'} ${showRebill && 'is-hidden'
                }`}
              type='button'
              onClick={handleGenerateLink}
              disabled={!hasErrorInputs}
            >
              Generar Link
            </button>
          </motion.div>
        )}
      </div>
    </FormStep>
  );
};
export default RebillCheckoutForm;
