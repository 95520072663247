import React from 'react';
import { countryOptions } from '../../../config/config';

const InvoiceDetailTrial = ({ invoiceDetail, currencyPayment }) => {
  const { advancePayment, formattedFirstPay, formattedPayPerMonth } = invoiceDetail;
  const { info, isTraditional, isSuscription, isAdvanceSuscription } = advancePayment;

  /*console.group('InvoiceDetail Component');
  console.log({ invoiceDetail });
  console.groupEnd();*/

  return (
    <>
      {isTraditional && (
        <div>
          <p className='mb-4'>
            Realiza el primer pago y, en los meses siguientes, completarás los pagos restantes.
          </p>
          <p className='item-deail-text mb-2'>{1} pago de:</p>
          <h3 className='title is-3 item-deail-text has-text-weight-bold'>{formattedFirstPay}</h3>
        </div>
      )}

      {(isSuscription || isAdvanceSuscription) && (
        <div>
          <p className='mb-4'>
            ¡Prepárate para la experiencia MSK! Con tu <strong>prueba de 7 días gratis</strong> podrás disfrutar de los contenidos principales del curso elegido. Accederás a ellos dentro de tu perfil personal, donde también podrás cancelar el período de prueba sin costo.
          </p>
          <p className='mb-4'>A partir del octavo día, se confirmará tu inscripción al curso completo.</p>

          <div className="my-5 p-3 pb-0" style={{ maxWidth: '350px', border: '1px solid #E4E4E4', borderRadius: '1rem' }}>
            <p className='mb-2 item-deail-text'>¡Ahora!</p>
            <h4 className="is-size-5 mb-3 has-text-weight-bold has-text-inter item-deail-text">Prueba gratuita de 7 días</h4>
            <span className="">
              Luego, {info.remainingQuotes + 1} pagos de{" "}
              <span className='has-text-weight-bold'>{formattedPayPerMonth}</span>
              {currencyPayment == 'Ecuador' && <span className='is-block'>+ impuestos aplicables</span>}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceDetailTrial;
