import React from 'react'
import InputSkeleton from "./InputSkeleton";

const CheckoutInputSkeleton = () => {

    return (
        <div className='column px-2 mt-5'>
            <div className="mb-4">
                <InputSkeleton />
            </div>

        </div>
    )
}

export default CheckoutInputSkeleton;