export const getIsoCode = (country) => {
  switch (country) {
    case 'Chile':
      return 'ch';
    case 'Argentina':
      return 'ar';
    case 'Mexico':
      return 'mx';
    case 'Ecuador':
      return 'ec';
    default:
      return 'usd';
  }
};
