import { useEffect, useState } from 'react';

function usePaymentIsCompleted(stripePromise) {
    const [messageBody, setMessageBody] = useState('');

    useEffect(() => {
        if (!stripePromise) return;

        stripePromise.then(async (stripe) => {
            const url = new URL(window.location);
            const clientSecret = url.searchParams.get('payment_intent_client_secret');
            if (!clientSecret) {
                setMessageBody('No payment intent client secret found.');
                return;
            }

            const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

            setMessageBody(error ? `> ${error.message}` : (
                <>&gt; Payment {paymentIntent.status}: <a href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
            ));
        });
    }, [stripePromise]);

    return messageBody;
}

export default usePaymentIsCompleted;
