export const valuesAdvanceSuscription = ({ total, checkoutPayment, exact }) => {
  //console.group('valuesAdvanceSuscription');
  console.log({ total, checkoutPayment, exact });
  const { quotes, type } = checkoutPayment;

  const isAdvanceSuscription = type.includes('Suscripción con anticipo');
  const isSuscription = type.includes('Suscripción') && !type.includes('anticipo');
  const isTraditional = type.includes('Tradicional');

  const detailValues = {
    isAdvanceSuscription,
    isSuscription,
    isTraditional,
    info: {},
  };

  if (isAdvanceSuscription) {
    const quoteForMonth = calculateAmount(exact, 'quote', total / quotes);
    const remainingQuotes = quotes === 1 ? 1 : quotes - 1;

    const firstQuoteDiscount = calculateAmount(exact, 'quote', quoteForMonth / 2);
    const remainingAmountToPay = total - firstQuoteDiscount;
    const payPerMonthAdvance = calculateAmount(
      exact,
      'quote',
      remainingAmountToPay / remainingQuotes,
    );
    const adjustmentPayment = calculateAmount(
      exact,
      'adjust',
      payPerMonthAdvance * remainingQuotes + firstQuoteDiscount - total,
    );

    detailValues.info = {
      remainingQuotes,
      firstQuoteDiscount,
      remainingAmountToPay,
      payPerMonthAdvance,
      adjustmentPayment,
    };
  } else if (isSuscription) {
    const quoteForMonth = calculateAmount(exact, 'quote', total / quotes);
    const remainingQuotes = quotes === 1 ? 1 : quotes - 1;
    const payPerMonthAdvance = quoteForMonth;
    const adjustmentPayment = calculateAmount(exact, 'adjust', payPerMonthAdvance * quotes - total);

    detailValues.info = {
      quoteForMonth,
      remainingQuotes,
      payPerMonthAdvance,
      adjustmentPayment,
    };
  } else {
    const quoteForMonth = calculateAmount(exact, 'quote', total / 1);

    const payPerMonthAdvance = quoteForMonth;
    const adjustmentPayment = calculateAmount(exact, 'adjust', payPerMonthAdvance - total);

    detailValues.info = {
      quoteForMonth,
      payPerMonthAdvance,
      adjustmentPayment,
    };
  }
  //console.groupEnd();

  return detailValues;
};

export const calculateAmount = (exact, typeCalculate, operation) => {
  switch (typeCalculate) {
    case 'quote':
      const quote = exact ? parseFloat(operation.toFixed(2)) : Math.floor(operation);
      return quote;
    case 'adjust':
      const adjust = exact ? 0 : parseFloat(operation.toFixed(2));
      return adjust;
    default:
      return 0;
  }
};
