import React from 'react';
import InvoiceDetailTrial from './InvoiceDetailTrial';

const CheckoutTrialCard = ({
  checkoutPayment,
  invoiceDetail,
  totalMonths,
  formattedFirstPay,
  formattedAmount,
  products,
  ticketData
}) => {
 // console.log({ ticketData })

  return (
    <div className='card my-4'>
      <div id='card' className='card-content has-text invoice-text pb-0'>
        <h1 className='title is-3 title-type has-text-weight-bold'>
          {checkoutPayment?.type === 'Suscripción con anticipo'
            ? 'Inscripción con anticipo TRIAL'
            : 'Finaliza tu inscripción de prueba'}
        </h1>

        {checkoutPayment?.type.includes('Suscripción') ? (
          <InvoiceDetailTrial invoiceDetail={invoiceDetail} currencyPayment={ticketData.ZohoData.sale.Pais_de_facturaci_n} />
        ) : (
          <div>
            <p>
              {totalMonths} {checkoutPayment?.type === 'Tradicional' ? 'pago unico de' : 'pagos de'}
            </p>
            <h3 className='title is-3'>
              {checkoutPayment?.type === 'Suscripción con anticipo'
                ? formattedFirstPay
                : formattedAmount}
            </h3>
          </div>
        )}
      </div>
      <hr className='is-divider-dashed' />
      <div className='px-5 py-2 invoice-text'>
        <h4 className='is-4 invoice-text mb-2'>Detalle de tu inscripci&oacute;n</h4>

        {products?.map((p) => (
          <div key={p.id} className='is-flex is-justify-content-space-between mb-2'>
            <span className='item-deail-text'>
              x{p.quantity} <span className='has-text-weight-bold item-deail-text'>{p.name}</span>
            </span>
            {/* <span className='has-text-weight-bold item-deail-text'>
              {new Intl.NumberFormat('MX', currencyOptions).format(Math.floor(p.price))}
            </span> */}
          </div>
        ))}
      </div>
      {/* <hr className='is-divider-dashed' />

      <div className='px-5 py-2 invoice-text is-flex is-justify-content-end '>
        {contractData?.sale?.Discount > 0 && (
          <>
            <h4 className='is-4 invoice-text mb-2 mr-2'>Descuento</h4>
            <span className='has-text-weight-bold item-deail-text mr-2'>
              {new Intl.NumberFormat('MX', currencyOptions).format(contractData.sale.Discount)}
            </span>
          </>
        )}
        <h4 className='is-4 invoice-text mb-2 mr-2'>Total</h4>
        <span className='has-text-weight-bold item-deail-text'>
          {new Intl.NumberFormat('MX', currencyOptions).format(
            totalPrice - contractData.sale.Discount,
          )}
        </span>
      </div> */}
    </div>
  );
};

export default CheckoutTrialCard;
