import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from 'react-bulma-components';

const FormNavigation = (props) => {
  const formik = useFormikContext();
  const disabledButtonDefault = props.isLastStep === !formik.values.cardComplete;
  const disabledButtonCTC = props.isLastStep === (formik.values.folio_suscripcion && formik.values.folio_pago);

  const disabledButton = formik.values?.payment_method?.includes("CTC") ? disabledButtonCTC : disabledButtonDefault
    const finishNavigationButtonOnPTP = props.isLastStep && formik.values?.payment_method?.includes("PlaceToPay")
    const finishNavigationButtonOnRebill = props.isLastStep && formik.values?.payment_method?.includes("Rebill")
    const finishNavigationButtonOnMP = props.isLastStep && formik.values?.payment_method?.includes("Mercado Pago")
    const finishNavigationButtonOnST = props.isLastStep && formik.values?.payment_method?.includes("Stripe")
    const onFinishNavigationCondition = (finishNavigationButtonOnPTP || finishNavigationButtonOnMP || finishNavigationButtonOnRebill || finishNavigationButtonOnST)

  return (
    <div className='controls'>
      {props.hasPrevious && (
        <Button
          className='flex-grow-1 is-primary is-outlined is-normal is-fullwidth'
          type='button'
          onClick={props.onBackClick}
        >
          Volver
        </Button>
      )}

      <Button
        className={`flex-grow-1 is-primary is-normal is-fullwidth ${ onFinishNavigationCondition && 'is-hidden'}`}
        disabled={disabledButton}
        type='submit'
      >
        {props.isLastStep ? 'Finalizar' : 'Siguiente'}
      </Button>
    </div>
  );
};

export default FormNavigation;
