import React from 'react';

const TextSkeleton = ({ className = "", lines = 1 }) => {
    const linesArray = Array.from({ length: lines });

    return (
        <>
            {linesArray.map((_, i) => (
                <div
                    key={`la_${i}`}
                    role="status"
                    className={`is-flex is-flex-direction-column is-align-items-center ${className}`}
                >
                        <div className="skeleton mb-2"></div>
                </div>
            ))}
        </>
    );
};

export default TextSkeleton;
