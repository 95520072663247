import React from 'react';
import {
  getPlanPriceCheckout,
  mappingCheckoutFields,
  REBILL_CL_CONF,
  REBILL_CONF,
  REBILL_COP_CONF,
  REBILL_MX_CONF,
  REBILL_UY_CONF,
  URLS,
} from '../Hooks/useRebill';
import { translateDocument } from '../../../utils/translateDocument';
import { handleSetContractStatus, handleSuscriptionUpdateCheckout } from '../../../logic/rebill';
import { fireModalAlert, fireModalAlertRedirect, fireToast } from '../Hooks/useSwal';
import axios from 'axios';
import { makePostUpdateZohoCheckout } from '../../../logic/zoho';
import { valuesAdvanceSuscription } from '../../../utils/valuesAdvanceSuscription';

const RebillCheckout = ({ payment, ticketData }) => {
  function initRebill(paymentLink) {
    const { paymentLinkData, ZohoData, advanceSuscription } = paymentLink;
    const { checkout, customer: paymentLinkCustomer } = paymentLinkData;
    const { contact, sale } = ZohoData;
    const parametrosURL = window.location.hash.includes('trial');
    const isTrial = Boolean(payment?.checkoutPayment?.trial) || parametrosURL;

    console.log({ isTrial, payment });

    function initialization() {
      const country = checkout.country;
      switch (country) {
        case 'Chile':
          return {
            organization_id: REBILL_CL_CONF.ORG_ID,
            api_key: REBILL_CL_CONF.API_KEY,
            api_url: REBILL_CL_CONF.URL,
          };
        case 'México':
          return {
            organization_id: REBILL_MX_CONF.ORG_ID,
            api_key: REBILL_MX_CONF.API_KEY,
            api_url: REBILL_MX_CONF.URL,
          };
        case 'Uruguay':
          return {
            organization_id: REBILL_UY_CONF.ORG_ID,
            api_key: REBILL_UY_CONF.API_KEY,
            api_url: REBILL_UY_CONF.URL,
          };
        case 'Colombia':
          return {
            organization_id: REBILL_COP_CONF.ORG_ID,
            api_key: REBILL_COP_CONF.API_KEY,
            api_url: REBILL_COP_CONF.URL,
          };
        default:
          return {
            organization_id: REBILL_CONF.ORG_ID,
            api_key: REBILL_CONF.API_KEY,
            api_url: REBILL_CONF.URL,
          };
      }
    }

    const rebillConfig = initialization();
    const RebillSDKCheckout = new window.Rebill.PhantomSDK(rebillConfig);

    const customerRebill = mappingCheckoutFields({ paymentLinkCustomer, contact, checkout });
    //Seteo de customer
    RebillSDKCheckout.setCustomer(customerRebill);
    const type = translateDocument(contact.Tipo_de_Documento);

    //Seteo de identidicacion del customer
    RebillSDKCheckout.setCardHolder({
      name: contact.Full_Name,
      identification: {
        type,
        value: paymentLinkCustomer.personalId,
      },
    });

    //Seteo de plan para cobrar
    const formValues = {
      payment_method: checkout.gateway,
      advanceSuscription,
      quotes: checkout.quotes,
      country: checkout.country,
    };

    const { id, quantity } = getPlanPriceCheckout(formValues, sale, isTrial);
    RebillSDKCheckout.setTransaction({
      prices: [
        {
          id,
          quantity,
        },
      ],
    }).then((price_setting) => console.log({ price_setting }));

    //Seteo de callbacks en saco de que el pago este correcto o tengo algun fallo
    const { UPDATE_CONTRACT, MP, REBILL } = URLS;

    RebillSDKCheckout.setCallbacks({
      onSuccess: (response) => {
        const { invoice, failedTransaction, pendingTransaction } = response;
        checkout.trial = isTrial;
        if (failedTransaction != null) {
          const { payment } = failedTransaction.paidBags[0];
          const { errorMessage } = payment;
          handleSetContractStatus(payment, checkout.contract_entity_id, isTrial);
          fireModalAlert('Error de pago', errorMessage, 'error');
          return;
        }

        if (pendingTransaction !== null) {
          const { payment } = pendingTransaction.paidBags[0];
          const { customer } = pendingTransaction.buyer;
          const dni = customer.personalIdNumber !== '' ? customer.personalIdNumber : contact.DNI;
          const paymentData = { checkout, customer, sale, payment, dni };
          axios
            .post(URLS.PENDING_PAYMENT, {
              ...payment,
              type: checkout.type,
              contract_id: paymentLinkData.checkout.contract_entity_id,
              paymentData: JSON.stringify(paymentData),
            })
            .then((res) => console.log({ res }))
            .catch((err) => console.log({ err }));
          handleSetContractStatus(payment, checkout.contract_entity_id, isTrial);

          fireModalAlertRedirect(
            'Pago pendiente',
            'El pago se esta aun procesando, aguarde a la notificacion de email',
            payment,
          );
          return;
        }

        fireModalAlert('Pago Realizado', '', 'success', 5000);

        const { paidBags, buyer } = invoice;
        const { payment, schedules } = paidBags[0];
        const { customer } = buyer;
        const [subscriptionId] = schedules;
        const QUOTES = checkout.quotes ? Number(checkout.quotes) : 1;

        const isAdvanceSuscription = checkout.type.includes('Suscripción con anticipo');
        const advanceSuscription = valuesAdvanceSuscription({
          total: ticketData.ZohoData.sale?.Grand_Total,
          checkoutPayment: checkout,
        });

        const dataForZoho = {
          isAdvanceSuscription,
          advanceSuscription,
          QUOTES,
          customer,
          payment,
          paymentLinkCustomer,
          checkout,
          sale,
          subscriptionId,
          trial: isTrial,
        };

        const postUpdateZoho = makePostUpdateZohoCheckout(dataForZoho);

        if (advanceSuscription.isAdvanceSuscription) {
          handleSuscriptionUpdateCheckout(postUpdateZoho.subscriptionId, advanceSuscription);
        }

        const URL = checkout.gateway.includes('Stripe')
          ? UPDATE_CONTRACT
          : checkout.gateway.includes('Rebill')
            ? REBILL
            : MP;

        axios
          .post(URL, postUpdateZoho)
          .then((res) => {
            console.log({ res });

            handleSetContractStatus(payment, checkout.contract_entity_id, isTrial);
            fireToast('Inscripción actualizada', 'success', 5000);
            setTimeout(() => {
              window.location.reload(true);
            }, 3000);
          })
          .catch((err) => {
            console.log({ err });
            fireToast('Inscripción no actualizada', 'error', 5000);
          });
      },
      onError: (error) => {
        console.error(error);
        fireModalAlert('Pago Fallido', error);
      },
    });

    //Seteo metadata de la suscripcio
    RebillSDKCheckout.setMetadata({
      so_number: 'x' + sale.SO_Number,
    });

    //Textos de validaciones con el elemento de la tarjeta
    RebillSDKCheckout.setText({
      card_number: 'Numero de tarjeta',
      pay_button: 'Pagar',
      error_messages: {
        emptyCardNumber: 'Ingresa el numero de la tarjeta',
        invalidCardNumber: 'El numero de la tarjeta es invalido',
        emptyExpiryDate: 'Enter an expiry date',
        monthOutOfRange: 'Expiry month must be between 01 and 12',
        yearOutOfRange: 'Expiry year cannot be in the past',
        dateOutOfRange: 'Expiry date cannot be in the past',
        invalidExpiryDate: 'Expiry date is invalid',
        emptyCVC: 'Enter a CVC',
        invalidCVC: 'CVC is invalid',
      },
    });

    RebillSDKCheckout.setStyles({
      fieldWrapper: {
        base: {
          maxWidth: 'auto',
          height: 'auto',
        },
        errored: {},
      },
      inputWrapper: {
        base: {
          maxWidth: 'auto',
          fontFamily: '"Inter"',
        },
      },
      errorText: {
        base: {},
      },
      button: {
        backgroundColor: '#E5E7EB;',
        borderRadius: '4px',
        color: '#374161',
      },
    });

    //Aplicar configuracion al DOM
    RebillSDKCheckout.setElements('rebill_elements');
  }

  const regex = /(Rechazado|pending)/i;
  if (ticketData.paymentLinkData.checkout.status.match(regex)) {
    initRebill(ticketData);
  }

  return (
    <div
      id='rebill_elements'
      className={`mt-5 is-flex is-justify-content-center is-align-items-center ${payment.checkoutPayment?.status.includes('Pendiente') ||
        (payment.checkoutPayment?.status.includes('Efectivo') && 'is-hidden')
        }`}
    ></div>
  );
};

export default RebillCheckout;
