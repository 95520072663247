export const getAvailableInstallments = (countryCode) => {

  switch (countryCode) {
    case 'ch': //Chile
      return [8, 6, 3];
    case 'ar':
      return [3, 6, 9,12];
    case 'ec':
      return [15, 12, 9, 6, 3];
    default:
      return [12, 9, 6, 3];
  }
};
