import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import usePaymentIsCompleted from '../../../hooks/usePaymentIsCompleted';
import { createStripePaymentToken, getEnvKey } from '../../../logic/stripe';
import CheckoutInputSkeleton from '../../Skeleton/ChecktouInputSkeleton';

// Carga tu clave pública de Stripe
const key = getEnvKey();
const stripePromise = loadStripe(key);

const CheckoutForm = ({ isTrial }) => {
  const stripeMessage = usePaymentIsCompleted(stripePromise);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [onPaymentRequest, setOnPaymentRequest] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOnPaymentRequest(true);
    if (!stripe || !elements) {
      setOnPaymentRequest(false);

      return;
    }
    if (isTrial) {
      const { error: stripeError, paymentIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });

      if (stripeError) {
        setError(stripeError.message);
      } else if (paymentIntent.status === 'succeeded') {
        setSuccess(true);
      }
      setOnPaymentRequest(false);
    } else {
      const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });

      if (stripeError) {
        setError(stripeError.message);
      } else if (paymentIntent.status === 'succeeded') {
        setSuccess(true);
      }

      setOnPaymentRequest(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='mx-4 mb-5'>
      <PaymentElement className='box mt-3 mb-4' />

      <div className='field'>
        <div className='control'>
          <button
            className='button msk-color-button is-fullwidth'
            type='submit'
            disabled={!stripe || !elements || onPaymentRequest}
          >
            {onPaymentRequest ? 'Procesando ...' : 'Finalizar'}
          </button>
        </div>
      </div>
      {error && <div className='notification is-danger'>{error}</div>}
      {success && <div className='notification is-success'>¡Pago Exitoso!</div>}
    </form>
  );
};

const StripePaymentForm = ({ payment, currency }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [found, setFound] = useState(null);
  const [waiting, setWaiting] = useState(true);
  const parametrosURL = window.location.hash.includes('trial');
  const isTrial = Boolean(payment.checkoutPayment?.trial) || parametrosURL;

  payment.checkoutPayment.trial = isTrial;
  var paymentDataAux = JSON.parse(payment.checkoutPayment.paymentData);
  paymentDataAux.formValues.trial = isTrial;
  payment.checkoutPayment.paymentData = JSON.stringify(paymentDataAux);
  useEffect(() => {
    if (!payment.advancePayment && !currency) return;
    createStripePaymentToken({
      payment,
      currency,
      setError,
      setClientSecret,
      setFound,
      setWaiting,
      type: payment.checkoutPayment.type,
    });
  }, []);

  const options = {
    clientSecret,
    theme: 'flat',
  };

  if (waiting) {
    return <CheckoutInputSkeleton />;
  }

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            isTrial={isTrial}
            isSuscription={payment.checkoutPayment.type.includes('Suscripción')}
          />
        </Elements>
      )}
      {error && <div className='notification is-danger has-text-centered mb-4 mx-4'>{error}</div>}
      {found && <div className='notification is-success has-text-centered mb-4 mx-4'>{found}</div>}
    </>
  );
};

export default StripePaymentForm;
