import React from 'react';
import stripeImg from '../../../img/pasarelaCobros/metPago/stripe.svg';
import rebillImg from '../../../img/pasarelaCobros/metPago/rebill.svg';
import mpImg from '../../../img/pasarelaCobros/metPago/mp.svg';
import RebillCheckout from '../FormPayment/RebillCheckout';
import StripePaymentForm from '../FormPayment/StripeCheckout';
import MercadoPagoCheckout from '../FormPayment/MercadoPagoCheckout';

const CheckoutInput = ({
  checkoutPayment,
  invoiceDetail,
  contractData,
  ticketData,
  currencyOptions,
  isTrial,
}) => {
  const isStripe = checkoutPayment?.gateway?.includes('Stripe');
  const isRebill = checkoutPayment?.gateway?.includes('Rebill');
  const redirectStatus = window.location.href.includes('redirect_status=succeeded');

  const renderPaymentComponent = () => {
    switch (checkoutPayment?.gateway) {
      case 'Rebill':
        return (
          <RebillCheckout
            payment={invoiceDetail}
            contractData={contractData}
            ticketData={ticketData}
          />
        );
      case 'Mercado Pago':
        return (
          <MercadoPagoCheckout invoice={invoiceDetail} />
        );
      case 'Stripe':
        return <StripePaymentForm payment={invoiceDetail} currency={currencyOptions.currency} />;
      default:
        return null;
    }
  };

  return (
    <div className='column'>
      {isTrial && (
        <div className='has-text-centered invoice-text mb-4'>
          Ingresa los datos de tu tarjeta de débito o crédito. <br />
          No se realizará ningún cargo hasta el octavo día.
        </div>
      )}
      <div className='mx-auto is-fullheight'>
        {checkoutPayment?.status.includes('Pendiente') ||
          checkoutPayment?.status.includes('Efectivo') ||
          redirectStatus ? (
          <div
            className={`rebill-box-response ${checkoutPayment?.status.includes('Pendiente') ? 'rebill-pending' : 'rebill-success'
              }`}
          >
            El pago realizado ha sido aprobado correctamente
          </div>
        ) : (
          <>{renderPaymentComponent()}</>
        )}

        <div className='is-flex is-justify-content-center is-align-items-center invoice-text'>
          <span>Pagos procesados con</span>
          <img
            src={isStripe ? stripeImg : isRebill ? rebillImg : mpImg}
            alt='Gateway'
            style={{ maxHeight: '25px' }}
            className='ml-2'
          />
        </div>
        {isTrial && (
          <a
            href='https://msklatam.com/terminos-y-condiciones/#trial'
            target='_blank'
            rel='noreferrer'
            className='checkout-tems-link is-flex is-justify-content-center is-align-items-center mt-4'
          >
            Ver términos y condiciones de prueba gratuita
          </a>
        )}
      </div>
    </div>
  );
};

export default CheckoutInput;
