import React from 'react';

const TrialCheckbox = ({ checked, error, onChange }) => {
  return (
    <div id='trialContainer' className='field'>
      <label className='label'>
        Trial
      </label>
      <div className='control is-flex is-align-content-center'>
        <strong>7 dias de prueba</strong>
        <input
          type='checkbox'
          name='trial'
          className='ml-2'
          checked={checked}
          onChange={onChange}

        />
      </div>
    </div>
  );
};

export default TrialCheckbox;
