import React from 'react';

const InputSkeleton = ({ className = ""}) => {
    return (
        <div role="status" className={`is-flex is-flex-direction-column is-align-items-center ${className}`}>
            <div className="skeleton mb-2" style={{ height: "40px"}}></div>
        </div>
    );
};

export default InputSkeleton;
