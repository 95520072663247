import React from 'react';
import TextSkeleton from "./TextSkeleton";

const CheckoutTextSkeleton = () => {
    return (
        <div className='card my-4 p-5 px-12' style={{ width: "480px"}}>
            <div className="mb-4">
                <TextSkeleton lines={2}/>
            </div>
            <div className="mb-4">
                <TextSkeleton lines={2}/>
            </div>
            <div className="mb-4">
                <TextSkeleton lines={1}/>
            </div>

            <div id='card' className='card-content has-text invoice-text'>
                <div className="mb-4">
                    <TextSkeleton lines={1}/>
                </div>

                <div className="mb-4">
                    <TextSkeleton lines={2}/>
                </div>
                <div className="mb-4">
                    <TextSkeleton lines={2}/>
                </div>
                <div className="mb-4">
                    <TextSkeleton lines={1}/>
                </div>
            </div>
            <hr className='is-divider-dashed'/>
            <div className='px-5 py-2 invoice-text'>
                <div className="mb-4">
                    <TextSkeleton lines={1}/>
                </div>
            </div>
            <hr className='is-divider-dashed'/>
            <div className="mb-4">
                <TextSkeleton lines={1}/>
            </div>
        </div>
    );
};

export default CheckoutTextSkeleton;
