import React from 'react';
import InvoiceDetail from './InvoiceDetail';
import CheckoutTextSkeleton from "../../Skeleton/CheckoutTextSkeleton";

const CheckoutCard = ({
  checkoutPayment,
  invoiceDetail,
  totalMonths,
  currencyOptions,
  formattedFirstPay,
  formattedAmount,
  products,
  totalPrice,
  contractData,
}) => {
  const currencyFormatter = new Intl.NumberFormat('MX', currencyOptions);

  if(!checkoutPayment) return <CheckoutTextSkeleton />;

  return (
    <div className='card my-4'>
      <div id='card' className='card-content has-text invoice-text'>
        <h1 className='title is-1 title-type'>
          {checkoutPayment?.type === 'Suscripción con anticipo'
            ? 'Inscripción con anticipo'
            : 'Finaliza tu inscripción'}
        </h1>

        {checkoutPayment?.type.includes('Suscripción') ? (
          <InvoiceDetail invoiceDetail={invoiceDetail} />
        ) : (
          <div>
            <p>
              {totalMonths} {checkoutPayment?.type === 'Tradicional' ? 'pago unico de' : 'pagos de'}
            </p>
            <h3 className='title is-3'>
              {checkoutPayment?.type === 'Suscripción con anticipo'
                ? formattedFirstPay
                : formattedAmount}
            </h3>
          </div>
        )}

      </div>
      <hr className='is-divider-dashed' />
      <div className='px-5 py-2 invoice-text'>
        <h4 className='is-4 invoice-text mb-2'>Detalle de tu inscripci&oacute;n</h4>

        {products?.map((p) => (
          <div key={p.id} className='is-flex is-justify-content-space-between mb-2'>
            <span className='item-deail-text'>
              x{p.quantity} {p.name}
            </span>
            <span className='has-text-weight-bold item-deail-text'>
              {currencyFormatter.format(Math.floor(p.price))}
            </span>
          </div>
        ))}
      </div>
      <hr className='is-divider-dashed' />

      <div className='px-5 py-2 invoice-text is-flex is-justify-content-end '>
        {contractData?.sale?.Discount > 0 && (
          <>
            <h4 className='is-4 invoice-text mb-2 mr-2'>Descuento</h4>
            <span className='has-text-weight-bold item-deail-text mr-2'>
              {currencyFormatter.format(contractData.sale.Discount)}
            </span>
          </>
        )}
        <h4 className='is-4 invoice-text mb-2 mr-2'>Total</h4>
        <span className='has-text-weight-bold item-deail-text'>
          {currencyFormatter.format(totalPrice - contractData.sale.Discount)}
        </span>
      </div>
    </div>
  );
};

export default CheckoutCard;
